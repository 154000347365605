<template>
  <AppLayout id='cont' class="content home-background1">
    <div class="title-about">{{intFace[53]}}</div>
    <div class="container-about" style="margin-bottom: 32px;">
      <p></p>
      <div id="apDiv115">
        <p>
          {{intFace[23]}}
          <br/>
          {{intFace[24]}}
          <br/>
          {{intFace[25]}}
          <br/>
          {{intFace[26]}}
        </p>
      </div>
      <div class="footer">
        <router-link to="/">{{intFace[52]}}</router-link>
      </div>
    </div>
  </AppLayout>
</template>

<script>
  export default {
    name: 'About',
    data() {
      return {
        lng: '',
        intFace: [],
      }
    },
    async mounted() {
      const lng = localStorage.getItem('lng') //this.lng
      this.intFace = (lng === 'ru') ? JSON.parse(localStorage.getItem('interface_ru')) :
        (lng === 'en') ? JSON.parse(localStorage.getItem('interface_en')) :
          (lng === 'de') ? JSON.parse(localStorage.getItem('interface_de')) : [];
    },
  }
</script>

<style scoped>
/*
  .footer {
    text-align: right;
    margin-top:8px;
  }
  #apDiv115{
    line-height:1.5;
    height: 83vh;
    border: 1px solid;
    overflow: scroll;
    scrollbar-width: none;
    overflow-wrap: break-word;
  }
*/
/*
  .container-about{
    !*padding-top:20px;*!
    background-color:revert;
    width:96%;
    scrollbar-width: none;
    overflow-wrap: break-word;
  }
  .title-about {
    font-size:30px;
    position:relative;
    top:7px;
  }
*/
/*
  .content {
    overflow-y:auto !important;
  }
*/
/*
  #apDiv112{
    position:absolute;
    left:0px;
    width:960px;
    z-index:51;
    background-image:url(../../public/img/11.png);
    background-size:cover;
    max-height:300px;
  }
  #apDiv113{
    position:relative;
    z-index:51;
  }
  #apDiv114{
    position:relative;
    top:21px;
    z-index:51;
  }
*/
</style>