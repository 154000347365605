<template>
  <AppLayout  id='cont' class="content" layoutClass="answer-background">
    <div id="apDiv38" style="display:block">
      <!--<img id="apDiv41" :src="require('@/assets/img/101_4.png')" alt=''>-->
      <div id="apDiv72" v-if="isActive1" style="display:block" @click="enter" class="cursor">
          <span
            class="span72"
            :class="lng === 'ru' ? 'ask-ru' : lng === 'en' ? 'ask-en' : 'ask-de'"
          >
            {{intFace[54]}}
          </span>
        <img src="img/rotat.gif" alt=''>
      </div>
      <div class="wrapper-window" v-if="isWrapper" @click="closeInfo1()"></div>
      <div class="toggleInfoAnsw">
        <div id="apDiv251" class="info3">
          <div class="text-info2">
            <u style="margin-bottom:10px">{{intFace[27]}}</u><br>
            <p class="text-info3">
              {{intFace[28]}}<br>
              {{intFace[29]}}<br>
              {{intFace[30]}}<br>
              {{intFace[31]}}
            </p>
          </div>
          <div class="pos-close" @click="closeInfo1()">x</div>
        </div>
        <div id="apDiv31" v-if="isActive1" class="hbord">
          <div
            id="apDiv107"
            class="info2"
            @click="info1()"
          > i
          </div>
          <h4 v-if="!isShowMulti"
            :class="{ 'titl-de': lng === 'de' }"
          >{{intFace[32]}}</h4>
          <div v-else class="top-title"><u v-html="titleAnswer"></u>
          </div>
          <div :class="isShowMulti ? 'pos-all' : 'pos-all1'">
            <div class="widthValue" :class="isShowMulti ? 'pos-sub10' : 'pos-sub9'">
              <div id="apDiv541" :class="isShowMulti ? 'pos-sub6' : 'pos-sub3'">
                <div class="up-value">{{answYes}}%</div>
              </div>
              <div id="apDiv551" :class="isShowMulti ? 'pos-sub7' : 'pos-sub1'">
                <div class="up-value">{{answNo}}%</div>
              </div>
            </div>
            <div id="apDiv40" class="top-answer" :class="isShowMulti ? 'pos-sub' : 'pos-sub4'">
              <div v-if="!isShowMulti">
                <p id="sts1" class="top">{{intFace[19]}}</p>
                <p id="sts2an" class="top">{{intFace[20]}}</p>
                <!--<p id="sts3" class="top">{{intFace[33]}}</p>-->
                <!--<p id="sts3" class="top">Нет</p>-->
              </div>
              <div v-else>
                <p id="sts11" class='top'>N{{numAnswer1}}</p>
                <p id="sts12" class='top'>N{{numAnswer2}}</p>
              </div>
              <p id="sts13"
               class="top"
               :class="isShowMulti ? 'pos-st13' : 'pos-st14'"
              >{{intFace[33]}}</p>
              <p id="sts4an" class="top" v-if="!isShowMulti">{{intFace[34]}}</p>
            </div>
            <div id="apDiv70"
              class="pos-sub2" v-show="!isShowMulti"
              :class="{ 'sub6-de': lng === 'de' }"
            >
              {{resultValue[3]}}%
            </div>
            <div id="apDiv701" :class="[{ 'sub5-de': lng === 'de' }, isShowMulti ? 'pos-sub8' : 'pos-sub5']">
              {{ this.resultValue[2] }}%
            </div>
          </div>
        </div>
      </div>
      <div id="apDiv73">
        <Ask />
      </div>
      <div id="apDiv47">
        <img id="magg" :src = "require('@/assets/img/opk8.gif')" class=" text-center" alt=''>
        <!--<img id="magg" src="img/opk8.gif" class=" text-center" alt=''>-->
        <!--<img :src = "require('@/assets/img/opk8.gif')" width="22" height="23" alt=''>-->
        <div id="apDiv60" class="text-center">
          <div>
            <div id="iii" class="subtitle" v-if="isShowMulti" :class="{ active: isActive }">
              {{intFace[35]}}
            </div>
            <div id="apDiv48" v-if="!isShowMulti" :class="{ active: isActive }">{{answer}}</div>
            <div id="apDiv488" class="answ-mult" v-else :class="{ active: isActive }">
              <table>
                <tbody>
                <tr v-for="(item, index) in dataObjects" :key="index">
                  <td>N{{ item.number }}</td>
                  <td class="razm">{{ item.textAnswer }}</td>
                  <td>{{ item.percentage }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row-text">
        <div id="apDiv59" class="text text-center">
          <div id="apDiv51" class="text1">
            <span class="quote-txt">{{quoteText1}}</span>
          </div>
          <div id="apDiv52" class="text2">{{autor1}}</div>
        </div>
        <div id="apDiv62" class="text text-center">
          <div id="apDiv53" class="text1">
            <span class="quote-txt">{{quoteText2}}</span>
          </div>
          <div id="apDiv54" class="text2">{{autor2}}</div>
        </div>
        <div id="apDiv65" class="text text-center">
          <div id="apDiv55" class="text1">
            <span class="quote-txt">{{quoteText3}}</span>
          </div>
          <div id="apDiv56" class="text2">{{autor3}}</div>
        </div>
        <div id="apDiv67" class="text text-center">
          <div id="apDiv57" class="text1">
            <span class="quote-txt">{{quoteText4}}</span>
          </div>
          <div id="apDiv58" class="text2">{{autor4}}</div>
        </div>
      </div>
      <!--<div id="apDiv43"><img src="img/znak17_4.gif" width="281" height="285" alt=''></div>-->
    </div>
  </AppLayout>
</template>

<script>
import $ from 'jquery';
import Ask from '../components/Ask';

export default {
  name: 'Answer',
  components: {Ask},
  data() {
    return {
      isLoading: true,
      isShowMulti: null,
      isActive: false,
      isActive1: true,
      isWrapper: false,
      intFace: [],
      resultValue: [],
      dataMultiAsk: [],
      percentages: [],
      lng: '',
      answYes: '',
      answNo: '',
      tableKey: 0,
      numAnswer1: 1,
      numAnswer2: 1,
      titleAnswer: '',
      quoteText1: '',
      quoteText2: '',
      quoteText3: '',
      quoteText4: '',
      autor1: '',
      autor2: '',
      autor3: '',
      autor4: '',
      aa3: null,
      answer: '',
      showBlock: false,
      newImages: [],
      // images: [],
      images: ['/img/101_4.png', '/img/svitki_05.png'],
    }
  },
  async mounted() {
    this.lng = localStorage.getItem('lng');
    this.intFace = JSON.parse(localStorage.getItem('intFace'))
    const backgroundImage = require('@/assets/img/101_5.jpg');
    document.body.style.backgroundImage = `url(${backgroundImage})`;
    // document.body.style.backgroundImage = 'url(/img/101_5.jpg)';
    // document.body.style.backgroundImage = "url(require('@/assets/img/101_5.jpg'))";

/* todo
    this.preloadImages(this.images).then(() => {
      this.newImages = this.images;
      localStorage.setItem('newImages', JSON.stringify(this.newImages))
      console.log('this.newImages+moun', this.newImages);
    });
*/
    if (localStorage.getItem('pageReboot') === 'true') {
      this.reboot()

    } else {
      this.dataMultiAsk = JSON.parse(localStorage.getItem('dataMultiAsk'))
      this.isShowMulti = localStorage.getItem('isShowMulti') === 'true'
      this.resultValue = this.$store.getters.getResultValue
      this.percentages = this.$store.getters.getPercentages
      if (this.isShowMulti) {
        this.titleAnswer = window.innerWidth < 526 ?
          `${this.intFace[36]}<br>${this.intFace[37]}` :
          `${this.intFace[36]}<br>${this.intFace[38]}`
        this.dataObjects()
      }
      localStorage.setItem('resultValue', JSON.stringify(this.resultValue))

      const a1 = this.resultValue[0]  //Да
      const a2 = this.resultValue[1]  //Нет
      this.answYes = a1
      this.answNo = a2
      localStorage.setItem('answYes', a1)
      localStorage.setItem('answNo', a2)
      localStorage.setItem('pageReboot', 'true')
      const a3 = this.resultValue[2]
      const a4 = this.resultValue[3]
      const a5 = this.resultValue[4]
      let aa1 = a1 > a2 ? a1 : a2
      aa1 = this.getRandomNumber(0, 70);
      this.aa3 = (aa1 * 0.7).toFixed(0)
      document.getElementById('apDiv31').style.opacity = 0
      let item
      item = (a1 > a2)
        ? (a4 > 50 ? 1 : 2)
        : (a4 > 50 ? 4 : 3);
      if (!this.isShowMulti) this.showAnswer(item)
      $("#apDiv72").css({opacity: "0"})
      $("#apDiv42").css({opacity: "0"})
      if (!this.isShowMulti) {
        $("#apDiv48").css({opacity: "1"})
      } else {
        $("#apDiv488").animate({opacity: "1"}, 1000)
        $("#iii").animate({opacity: "1"}, 1000)
      }
      $("#apDiv42").animate({opacity: "0.5"}, 2400)
      await this.sleep(100);
      $("#apDiv31").css({opacity: "0"}).animate({opacity: "1"}, 2400)
      const wt = window.innerWidth < 526 ? 90 : 200
      const maxNum = Math.max(a1, a2);
      const b1 = wt / maxNum
      const a11 = a1 * b1
      const a21 = a2 * b1
      const dop = 0
      const widthYes = dop + a11
      const widthNo = dop + a21
      document.getElementById('apDiv541').style.width = widthYes + 'px'
      document.getElementById('apDiv551').style.width = widthNo + 'px'
      localStorage.setItem('widthYes', widthYes)
      localStorage.setItem('widthNo', widthNo)
      if (document.getElementById('apDiv701')) {
        document.getElementById('apDiv701').innerHTML = a3 + '%'
        document.getElementById('apDiv70').innerHTML = a4 + '%'
      }
      this.increaseSize1(5, 'magg', 2400)
      this.increaseSize1(5, 'apDiv60', 2400)
      this.increaseSize1(1, 'apDiv59', 2400);
      this.increaseSize1(2, 'apDiv62', 2400);
      this.increaseSize1(3, 'apDiv65', 2400);
      this.increaseSize1(4, 'apDiv67', 2400);
      await this.sleep(1500);
      $("#apDiv72").css({opacity: "1"})
      localStorage.setItem('numAsk', +localStorage.getItem('numAsk') + 1)
      document.getElementById("apDiv42").style.opacity = "0.5"
    }
  },
  methods: {
    info1() {
      this.isWrapper = true
      document.getElementById("apDiv251").style.display = 'block'
      document.getElementById("apDiv31").style.display = 'none'
    },
    closeInfo1() {
      this.isWrapper = false
      document.getElementById("apDiv251").style.display = 'none'
      document.getElementById("apDiv31").style.display = 'block'
    },
    preloadImages(imageUrls) {
      return Promise.all(
        imageUrls.map((url) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = url;
            img.onload = resolve;
            img.onerror = reject;
          });
        })
      );
    },
    applyStyles(widthYes, widthNo) {
      $(document).ready(function() {
        $("#apDiv541").css({'width': widthYes});
        $("#apDiv551").css({'width': widthNo});
      });
    },
    rebootQuote(url) {
      if(url) {
        $(url).css('transition', 'none')
          .show()
          .css({opacity: "1", 'transform': 'scale(1)'})
          .promise()
          .done(function () {
            $(this).height();
            $(this).css('transition', 'transform 2.4s ease, opacity 2.4s ease');
          });
      } else {
        console.log('!!!-???')
      }
    },
    async reboot() {
      $("#apDiv42").hide();
      this.isActive1 = false
      this.isShowMulti = localStorage.getItem('isShowMulti') === 'true'
      
/* todo
      // '/img/101_4.png'
      // this.newImages = JSON.parse(localStorage.getItem('newImages'))
      const img = new Image();
      // img.src = this.newImages[0];
      // img.src = this.newImages[0];
      // img.src = "require(this.newImages[0])"
      
      await img.decode(); // Дождитесь загрузки изображения
      document.getElementById('apDiv38').style.backgroundImage = `url(${img.src})`;
*/

/*
      const img = new Image();
      img.src = "require('/img/101_4.png')"
      // img.src = "require('@/assets/img/101_4.png')"
      await img.decode(); // Дождитесь загрузки изображения
*/
      await this.sleep(100)
      // document.getElementById('apDiv38').style.backgroundImage = `url(${img.src})`;
      const backgroundImage = require('@/assets/img/101_4.png');
      document.getElementById('apDiv38').style.backgroundImage = `url(${backgroundImage})`;

      this.isActive = true
      this.isActive1 = true
      this.rebootQuote("#magg")
      this.rebootQuote("#apDiv60")
      this.rebootQuote("#apDiv59")
      this.rebootQuote("#apDiv62")
      this.rebootQuote("#apDiv65")
      this.rebootQuote("#apDiv67")
      $("#apDiv541").show();
      $("#apDiv42").show();
      $("#apDiv51").show();
      $("#apDiv53").show()
      $("#apDiv55").show()
      $("#apDiv57").show()
      $("#apDiv62").css({opacity: "1"})
      $("#apDiv65").css({opacity: "1"})
      $("#apDiv67").css({opacity: "1"})
      this.answer = localStorage.getItem('answer')
      this.resultValue = JSON.parse(localStorage.getItem('resultValue'))
      this.quoteText1 = localStorage.getItem('quoteText1')
      this.quoteText2 = localStorage.getItem('quoteText2')
      this.quoteText3 = localStorage.getItem('quoteText3')
      this.quoteText4 = localStorage.getItem('quoteText4')
      this.autor1 = localStorage.getItem('autor1')
      this.autor2 = localStorage.getItem('autor2')
      this.autor3 = localStorage.getItem('autor3')
      this.autor4 = localStorage.getItem('autor4')
      this.answYes = localStorage.getItem('answYes')
      this.answNo = localStorage.getItem('answNo')
      const widthYes = localStorage.getItem('widthYes') + 'px'
      const widthNo = localStorage.getItem('widthNo') + 'px'
      console.log('++',widthYes,typeof(widthYes))
      if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', this.applyStyles(widthYes, widthNo));
      } else {
        this.applyStyles(widthYes, widthNo);
      }
      if (this.isShowMulti) {
        this.titleAnswer = window.innerWidth < 526 ?
          "Параметры для двух вероятных ответов" :
          "Параметры для двух наиболее вероятных ответов"
        this.dataObjects = JSON.parse(localStorage.getItem('dataObjects'))
      }
    },
    dataObjects() {
      this.dataObjects = this.dataMultiAsk.map((item, index) => {
        const number = index + 1
        return {
          number,
          textAnswer: item,
          percentage: this.percentages[index],
        };
      });
      this.dataObjects.sort((a, b) => {
        const percentageA = parseFloat(a.percentage);
        const percentageB = parseFloat(b.percentage);
        return percentageB - percentageA;
      });
      let bv11, bv21
      if (this.dataObjects && this.dataObjects.length > 0) {
        this.numAnswer1 = this.dataObjects[0].number
        this.numAnswer2 = this.dataObjects[1].number
        bv11 = this.dataObjects[0].percentage;
        bv21 = this.dataObjects[1].percentage;
      }
      const bv31 = this.resultValue[2];
      const znam =  70 //bv11 - bv21 > 0 ? bv11 : bv21
      const a4 = +(Math.abs(bv11 - bv21) * 100 / znam).toFixed(0)
      const a5 = a4 > 37.5 && a4 < 72.5 ? 2 : 1
      this.dataObjects = this.dataObjects.map(obj => ({
        ...obj,
        percentage: obj.percentage + "%"
      }));
      localStorage.setItem('dataObjects', JSON.stringify(this.dataObjects))
      this.resultValue = [
        bv11,
        bv21,
        bv31,
        a4,
        a5
      ]
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    getRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    async showAnswer(n) {
      let an, aa4, nn1, nn2
      const module = await import(`../data/answer_${this.lng}.js`);
      if (n === 1 || n === 4) {
        aa4 = (this.getRandomNumber(0, 30) * 0.3).toFixed(0)
      } else {
        aa4 = (this.getRandomNumber(0, 20) * 0.2).toFixed(0)
      }
      if (n === 1) an = module.answer1[aa4]
      if (n === 2) an = module.answer2[aa4]
      if (n === 3) an = module.answer3[aa4]
      if (n === 4) an = module.answer4[aa4]
      this.answer = an
      localStorage.setItem('answer', an)
    },
    async increaseSize1(n, id1, time) {
      const id11 = "#" + id1
      if (n !== 5) {
        const module = await import(`../data/quote_${this.lng}.js`);
        const moduleAnswer = await import(`../data/answer_${this.lng}.js`);
        let qt
        if (n === 1) qt = module.quote1[this.aa3]
        if (n === 2) qt = module.quote2[this.aa3]
        if (n === 3) qt = module.quote3[this.aa3]
        if (n === 4) qt = module.quote4[this.aa3]
        let qt1, qt2
        if (qt.includes("/")) {
          [qt1, qt2] = qt.split("/");
        } else {
          qt2 = qt
          qt1 = ""
        }
        if (document.getElementById('apDiv51')) {
          if (n === 1) {
            this.quoteText1 = qt2
            this.autor1 = qt1
            localStorage.setItem('quoteText1', qt2)
            localStorage.setItem('autor1', qt1)
            if (qt1 === '') document.getElementById('apDiv51').style.borderBottom = 'none'
          }
          if (n === 2) {
            this.quoteText2 = qt2
            this.autor2 = qt1
            localStorage.setItem('quoteText2', qt2)
            localStorage.setItem('autor2', qt1)
            if (qt1 === '') document.getElementById('apDiv53').style.borderBottom = 'none'
          }
          if (n === 3) {
            this.quoteText3 = qt2
            this.autor3 = qt1
            localStorage.setItem('quoteText3', qt2)
            localStorage.setItem('autor3', qt1)
            if (qt1 === '') document.getElementById('apDiv55').style.borderBottom = 'none'
          }
          if (n === 4) {
            this.quoteText4 = qt2
            this.autor4 = qt1
            localStorage.setItem('quoteText4', qt2)
            localStorage.setItem('autor4', qt1)
            if (qt1 === '') document.getElementById('apDiv57').style.borderBottom = 'none'
          }
        }
      }
        let $div = $(id11);
        $div.css('display', 'block');
        setTimeout(function () {
          $div.css({transform: 'scale(1)', opacity: 1});
        }, 10);
      
    },
    
    async showQuote(n, id1, id2, id3, time) {
      const module = await import(`../data/quote_${this.lng}.js`);
      const moduleAnswer = await import(`../data/answer_${this.lng}.js`);
      let qt
      if (n === 1) qt = module.quote1[this.aa3]
      if (n === 2) qt = module.quote2[this.aa3]
      if (n === 3) qt = module.quote3[this.aa3]
      if (n === 4) qt = module.quote4[this.aa3]
      const id11 = "#" + id1
      const id12 = "#" + id2
      const id13 = "#" + id3
      $(id11).css({opacity: "1"})
      await this.sleep(time + 400);
      let qt1, qt2
      if (qt.includes("/")) {
        [qt1, qt2] = qt.split("/");
      } else {
        qt2 = qt
        qt1 = ""
      }
      if (document.getElementById('apDiv51')) {
        if (n === 1) {
          this.quoteText1 = qt2
          localStorage.setItem('quoteText1', qt2)
          localStorage.setItem('autor1', qt1)
          if (qt1 === '') document.getElementById('apDiv51').style.borderBottom = 'none'
        }
        if (n === 2) {
          this.quoteText2 = qt2
          localStorage.setItem('quoteText2', qt2)
          localStorage.setItem('autor2', qt1)
          if (qt1 === '') document.getElementById('apDiv53').style.borderBottom = 'none'
        }
        if (n === 3) {
          this.quoteText3 = qt2
          localStorage.setItem('quoteText3', qt2)
          localStorage.setItem('autor3', qt1)
          if (qt1 === '') document.getElementById('apDiv55').style.borderBottom = 'none'
        }
        if (n === 4) {
          this.quoteText4 = qt2
          localStorage.setItem('quoteText4', qt2)
          localStorage.setItem('autor4', qt1)
          if (qt1 === '') document.getElementById('apDiv57').style.borderBottom = 'none'
        }
        document.getElementById(id3).innerHTML = qt1
      }
    },
    shuffle(array) {
      $(body).css({'background': '/img/images3.jpg'})
      let currentIndex = array.length, temporaryValue, randomIndex
      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1
        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
      }
      return array
    },
    random(n) {
      return this.shuffle(Array.from({length: 71}, (_, i) => i + 20))[n]
    },
    enter() {
      const backgroundImage = require('@/assets/img/101_5.jpg');
      document.body.style.backgroundImage = `url(${backgroundImage})`;
      
      // document.body.style.backgroundImage = 'url(/img/101.png)';
      // document.body.style.backgroundImage = 'url(/img/101.png)';
      // element.style.backgroundImage = `url('../img/${urlAnswer}')`;

      this.$router.push({path: '/'});
    },
  }
}
</script>

<style scoped>
/*
  .loading {
    transition: width 3s ease;
  }
*/
/*
  #apDiv551 {
    top: 33px;
  }
*/
  .pos-multiAsk {
    position:absolute;
    top:5px;
    overflow:scroll;
    height:50px;
    scrollbar-width: none;
    padding:0 12px;
  }
/*
  .tb-ask {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 58px;
    border: 1px solid rgb(0, 0, 0);
    font-size: 0.57rem;
    !*padding: 1.5px 5px;*!
    padding: 0 3px 3px 1px;
    margin-top: -1px;
    height: 11px;
  }
*/
  #apDiv74 {
    left: -307px;
    top: -65px;
  }
  #apDiv42 {
    right:0;
    width:275px;
  }
  .quote-txt{
    margin: 0;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    height: 60px;
    /*border: 1px solid;*/
    overflow: scroll;
    width: 90%;
    scrollbar-width: none;
  }
/*
#apDiv470 {
  display: none;
  animation: fadeIn 2.4s ease-in-out;
}
@keyframes fadeIn {
  0% { opacity: 0; transform: scale(0.5); }
  100% { opacity: 1; transform: scale(1); }
}
.cont-anim {
  display: flex;
  justify-content: center;
  align-items: center;
  !* Дополнительные стили *!
  width: 100%; !* Ширина контейнера *!
  height: 100vh; !* Высота контейнера (100% высоты окна браузера) *!
}
*/
.show-block {
  opacity: 1;
  transform: scale(1);
}
/*
#sts3, #sts2, #sts4 {
  margin-top: 1px;
}
*/
table {
  /*width: 100%;*/
  border-collapse: collapse;
}
th, td {
  border: 1px solid #ddd;
  padding: 3px;
  text-align: left;
}
th {
  background-color: #f2f2f2;
}
/*
.answ-mult {
  width: 265px;
  top: 39px;
  position: absolute;
  left: 32px;
  height: 121px;
  overflow: scroll;
  scrollbar-width: none;
  z-index:13;
}
*/
/*
  .razm {
    scrollbar-width: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 151px;
  }
*/
  .subtitle {
    padding-bottom: 8px;
    text-decoration: underline;
  }
  .active {
    opacity:1 !important;
  }
/*
.custom-background {
  background-image: url('../../public/img/101_4.png');
}
*/
#loading {
  height: 154px;
  position: absolute;
  top: 36px;
  left: 92px;
}
/*
.center-text {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0;
}
*/
.text-center {
  /*display: none;*/
  transform: scale(0);
  transform-origin: center;
  transition: transform 2.4s ease, opacity 2.4s ease;
  opacity: 0;
}
.top-title {
  position: relative;
  left: -8px;
}
  #magg, #apDiv60, #iii, #apDiv488, #apDiv48 {
    opacity:1;
  }
</style>