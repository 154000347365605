<template>
  <div id="apDiv42">
    <div v-if="isAsk" class="wrap-text"
      :class="isCheck === 'true' ? 'ask-y' : ''"
    >
      <!--<div v-if="isShowMulti" class="butt-top" @click="toggleVisibility"><i class="arrow-down">&#8595;</i></div>-->
      <!--<div id="line" :class="isShowMulti ? 'pos-multiAsk' : 'pos-no-multiAsk'">-->
      <div id="line" :class="isShowMulti ? 'pos-multiAsk' : 'pos-no-multiAsk'">
         {{ ask }}
      </div>
    </div>
    <div id="apDiv74" v-if="isAnswer">
      <div v-if="isShowMulti" class="butt-bott" @click="toggleVisibility">
        <i class="arrow-up" style="rotate: 180deg">&#8595;</i>
      </div>
      <table id="tb2"
        style='width:200px; border:0;'
        :class="dataMultiAsk1.length > 6 ? 'top1' :
         dataMultiAsk1.length > 2 && dataMultiAsk1.length < 6 ?
          'top2' : 'top3'"
      >
      <tr v-for="i in 3" :key="i">
          <td v-for="j in 2" :key="j" class="td-ask">
            <div :id="'tb' + i + j" class="tb-ask"
              v-if="dataMultiAsk[(i - 1) * 2 + (j - 1)]"
            >
              {{ dataMultiAsk[(i - 1) * 2 + (j - 1)] }}
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Ask",
    data() {
      return {
        isAsk: true,
        isAnswer: false,
        isVisAsk: false,
        isPosAnswer: false,
        isShowMulti: false,
        isCheck: null,
        intFace: [],
        dataMultiAsk: [],
        dataMultiAsk1: [],
        ask: '',
        txtDown: "&#8595;"
      }
    },
    methods: {
      toggleVisibility() {
        this.isAsk = !this.isAsk;
        this.isAnswer = !this.isAnswer;
        this.isVisAsk = !this.isVisAsk;
      },
      checkWindowSize() {
        this.isPosAnswer = window.innerWidth > 222961;
        this.isAsk = this.isPosAnswer || !this.isVisAsk;
        this.isAnswer = this.isPosAnswer ? this.isAsk : !this.isAsk;
        if (this.isPosAnswer) {
          document.getElementById('line').style.borderBottom = '1px solid'
        }
      }
    },
    mounted() {
      this.isCheck = localStorage.getItem('check')
      // if (this.isCheck) $('.pos-no-multiAsk').css('overflow', 'auto')
      this.intFace = JSON.parse(localStorage.getItem('intFace'))
      this.isShowMulti = localStorage.getItem('isShowMulti') === 'true'
      this.ask = localStorage.getItem('ask')
      if (this.isShowMulti) {
        this.dataMultiAsk = JSON.parse(localStorage.getItem('dataMultiAsk'))
        this.dataMultiAsk1 = this.dataMultiAsk.filter(item => item !== "");
        console.log('this.dataMultiAsk',this.dataMultiAsk)
      }
      this.checkWindowSize();
      window.addEventListener('resize', this.checkWindowSize);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.checkWindowSize);
    }
  }
</script>

<style scoped>
/*
.wrap-text {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 41%;
  width: 75%;
}
*/
.butt-top {
  display:none;
/*
  position: absolute;
  top: 6px;
  left: 260px;
  cursor: pointer;
  z-index: 18;
  text-decoration:underline;
  width: 13px;
  height: 13px;
  padding: 3px;
  text-align: center;
  display: flex;
  justify-content: center;
  border-radius: 10px;
*/
}
.butt-bott {
  position: absolute;
  cursor: pointer;
  z-index: 18;
  text-decoration:underline;
  left:256px;
  top:2px;
  width: 13px;
  height: 13px;
  padding: 3px;
  text-align: center;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}
/*
.pos-multiAsk {
  position:absolute;
  overflow:scroll;
  height:50px;
  scrollbar-width: none;
  top: 2px;
  width: 237px;
  left: 16px;
}
*/
#apDiv74 {
  left:-4px;
  position:relative;
  margin:0 auto;
  top: -8px;
  width:283px;
  height:50px;
  z-index:18;
  opacity:1;
}
.top1 {
  top: 1px;
  left: 6px !important;
}
.top2 {
  top: 14px;
  left: 6px !important;
}
.top3 {
  top: 14px;
  left: 8px !important;
}
.arrow-down {
  border-bottom: 2px solid;
  width: 10px;
  display: block;
  text-align: center;
  height: 5px;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  top: -2px;
}
.arrow-up {
  border-bottom: 1px solid;
  width: 10px;
  display: block;
  text-align: center;
  height: 6px;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  top: 14px;
  left: 4px;
}
.butt-bott:hover {
  border: 1px solid;
  background: #ecdedb;
}
.butt-top:hover {
  border: 1px solid;
  background: #ecdedb;
}
.borderNo {
  border:none;
}
</style>