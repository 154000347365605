import { createStore } from 'vuex'

export default createStore({
  state: {
    text: '',
    urlAnswer: '',
    truth: 60,
    isShowMulti: null,
    // isLeftArrow: false,
    dataMultiAsk: [],
    resultValue: [],
    percentages: []
  },
  getters: {
    getText(state) {
      return state.text;
    },
    getUrlAnswer(state) {
      return state.urlAnswer;
    },
    getShowMulti(state) {
      return state.isShowMulti;
    },
    // getLeftArrow(state) {
    //   return state.isLeftArrow;
    // },
    getDataMultiAsk(state) {
      return state.dataMultiAsk;
    },
    getResultValue(state) {
      return state.resultValue;
    },
    getPercentages(state) {
      return state.percentages;
    },
    getTruth(state) {
      return state.truth;
    }
  },
  mutations: {
    loadText(state, text) {
      state.text = text;
    },
    loadUrl(state, payload) {
      state.urlAnswer = payload;
    },
    showMulti(state, payload) {
      // localStorage.setItem('isShowMulti', payload)
      state.isShowMulti = payload;
    },
    // leftArrow(state, payload) {
    //   state.isLeftArrow = payload;
    // },
    dataMultiAsk(state, payload) {
      state.dataMultiAsk = payload;
    },
    resultValue(state, payload) {
      state.resultValue = payload;
    },
    percentages(state, payload) {
      state.percentages = payload;
    },
    truth(state, payload) {
      state.truth = payload;
    }
  },
  actions: {
    loadText({ commit }, text) {
      commit('loadText', text);
    },
    loadUrl({ commit }, payload) {
      commit('loadUrl', payload);
    },
    showMulti({ commit }, payload) {
      commit('showMulti', payload);
    },
    // leftArrow({ commit }, payload) {
    //   commit('leftArrow', payload);
    // },
    dataMultiAsk({ commit }, payload) {
      commit('dataMultiAsk', payload);
    },
    resultValue({ commit }, payload) {
      commit('resultValue', payload);
    },
    percentages({ commit }, payload) {
      commit('percentages', payload);
    },
    truth({ commit }, payload) {
      commit('truth', payload);
    }
  },
  modules: {
  }
})
