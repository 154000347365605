<template>
  <img alt="" id="apDiv410" :src="require('@/assets/img/neobychnye-kartinki9.gif')">
  <!--<img alt="" id="apDiv410" src="img/neobychnye-kartinki9.gif">-->
  <!--<img :src="require('@/assets/img/neobychnye-kartinki9.gif')" width="283" height="99" alt=''>-->

  <AppLayout id='cont' class="content">
    <div id="apDiv38" style="display:block">
      <img id="apDiv41" :src="require('@/assets/img/neobychnye-kartinki9.gif')" alt=''>
      <!--<img id="apDiv41" src="img/neobychnye-kartinki9.gif" alt=''>-->
      <div id="apDiv71" v-if="!isLoading"></div>
      <div id="apDiv31c">
        <h4
          :class="lng === 'ru' ? 'title-ru' : lng === 'en' ? 'title-en' : 'title-de'"
        >{{intFace[18]}}</h4>
        <div :class="{ loading: !isLoading }">
          <div id="apDiv541"></div>
          <div id="apDiv551"></div>
          <div id="apDiv651"></div>
        </div>
        <div id="apDiv40"
          class="top-choose"
          :class="{ 'text40-de': lng === 'de' }"
        >
          <div v-if="!isShowMulti">
            <p id='sts1'>{{intFace[19]}}</p>
            <p id='sts2'>{{intFace[20]}}</p>
            <p id='sts4'>~ </p>
          </div>
          <div v-else-if="isShowMulti">
            <p id='sts11'>N{{numAnswer1}}</p>
            <p id='sts12-ch'>N{{numAnswer2}}</p>
            <p id='sts14'>~ </p>
          </div>
        </div>
        <div id="apDiv70"></div>
      </div>
      <div id="apDiv73">
        <Ask/>
        <div id="apDiv28" v-if="isLoading">
          <h4 :class="{ 'text-ask-de': lng === 'de' }">{{intFace[21]}}</h4>
        </div>
          <div id='apDiv44' v-if="isLoading"></div>
        <div id="apDiv45" v-if="isLoading">
          <img :src="require('@/assets/img/jen-6.gif')" width="283" height="99" alt=''>
          <!--<img src="img/jen-6.gif" width="283" height="99" alt=''>-->
          <!--<img :src="require('@/assets/img/jen-6.gif')" id='rot' alt=''>-->
        </div>
      </div>
      <div id="apDiv46" class="cursor" @click="loading" v-if="isLoading">
        <img :src="require('@/assets/img/rotat.gif')" id='rot' alt=''>
        <!--require('@/assets/img/str.png')-->
        <!--<img src="img/rotat.gif" id='rot' alt=''>-->
      </div>
      <!--<div id="apDiv43"><img src="img/znak17_4.gif" width="281" height="285" alt=''></div>-->
    </div>
  </AppLayout>
</template>

<script>
  import $ from 'jquery'
  import Ask from '../components/Ask'

  export default {
    name: 'Home',
    components: {Ask},
    data() {
      return {
        isLoading: true,
        lng: '',
        intFace: [],
        urlAnswer: '',
        isShowMulti: null,
        // isCheck: null,
        numAnswer1: '?',
        numAnswer2: '?',
        tim1: null,
        dataMultiAsk: [],
        result1: [],
        truth: 60,
        c1: 0,
        c2: 0,
      }
    },
    async mounted() {
      // localStorage.setItem('check', this.isCheckboxChecked1)
      await this.stopInterval();
      this.lng = localStorage.getItem('lng')
      this.intFace = JSON.parse(localStorage.getItem('intFace'))
/*
      $('#rot').hide()
      const img = new Image();
      // img.src = '/img/rotat.gif';
      img.src = "require('@/assets/img/rotat.gif')"
      img.onload = () => {
        $('#rot').css('background', `${img.src}`).show();
      };
*/

/*
      const img = new Image()
      // img.src = '/img/svitki_09.png'
      img.src = "require('@/assets/img/svitki_09.png')"
*/
      
      // let url = localStorage.getItem('myMag')
      localStorage.setItem('pageReboot', 'false')
      this.dataMultiAsk = JSON.parse(localStorage.getItem('dataMultiAsk'))
     
      // this.urlAnswer = this.$store.getters.getUrlAnswer
      this.urlAnswer = localStorage.getItem('myMag')
      console.log('!1',this.urlAnswer)
      if (this.urlAnswer === "") {
        this.urlAnswer = localStorage.getItem('myMag').match(/\/([^/]+)$/)[1];
      }
      
      this.isShowMulti = this.$store.getters.getShowMulti
      this.isShowMulti = localStorage.getItem('isShowMulti') === 'true'

      this.truth = this.$store.getters.getTruth
      this.placingProbability()
      this.intervalFish = setInterval(() => this.showFish(), 1000)

      const urlAnswer = this.urlAnswer;
      const element = document.getElementById('apDiv44');
      element.style.backgroundImage = `url('${urlAnswer}')`;
      // element.style.backgroundImage = `url('../img/${urlAnswer}')`;
      // element.style.backgroundImage = `url('../img/${urlAnswer}')`;

      // const imagePath = require(`@/assets/img/${urlAnswer}`);
      // element.style.backgroundImage = `url(${imagePath})`;

      // this.loadImage(this.urlAnswer);
      
      element.style.backgroundSize = 'contain'
      element.style.backgroundRepeat = 'no-repeat'
    },
    beforeDestroy() {
      clearInterval(this.tim1);
    },
    beforeRouteLeave() {
      clearInterval(this.tim1);
    },
    methods: {
      async loadImage(urlAnswer) {
        try {
          const imagePath = await import(`@/assets/img/${urlAnswer}`);
          this.$refs.backgroundElement.style.backgroundImage = `url(${imagePath.default})`;
        } catch (error) {
          console.error('Ошибка загрузки изображения:', error);
        }
      },
      shuffle(array) {
        let currentIndex = array.length, temporaryValue, randomIndex
        while (0 !== currentIndex) {
          randomIndex = Math.floor(Math.random() * currentIndex)
          currentIndex -= 1
          temporaryValue = array[currentIndex]
          array[currentIndex] = array[randomIndex]
          array[randomIndex] = temporaryValue
        }
        return array
      },
      delta(progress) {
        function d(progress) {
          return 1 - Math.sin(Math.acos(progress))
        }
        return 1 - d(1 - progress)
      },
      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },
      stopInterval() {
        return new Promise((resolve) => {
          if (this.tim1) {
            clearInterval(this.tim1);
            this.tim1 = undefined;
          }
          resolve();
        });
      },
      async loading() {
        const self = this
        await this.stopInterval();
        this.isLoading = false
        document.getElementById('apDiv541').style.width = '0px'
        document.getElementById('apDiv551').style.width = '0px'
        document.getElementById('apDiv651').style.width = '0px'
        await this.sleep(100);
        let a2 = 0, result = 0
        const to = window.innerWidth < 526 ? 100 : 200
        const duration = 3000
        const start = new Date().getTime()
        const myInterval = setInterval(
          function () {
            const now = (new Date().getTime()) - start
            const progress = now / duration
            result = to * self.delta(progress)
            if (result < a2) { //199
              clearInterval(myInterval)
              self.$router.push({path: '/answer'})
            }
            a2 = result
            if (document.getElementById('apDiv541')) {
              document.getElementById('apDiv541').style.width = result + "px"
              document.getElementById('apDiv551').style.width = result + "px"
              document.getElementById('apDiv651').style.width = result + "px"
            }
          }
          , 50)
      },
      getRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      },
      placingProbability() {
        document.getElementById("apDiv40").style.display = "block"
        let bv11 = 0, bv21 = 0, bv31 = 0, bv110 = 0, bv210 = 0, bv310 = 0
        document.getElementById('apDiv541').style.width = '0'
        document.getElementById('apDiv551').style.width = '0'
        document.getElementById('apDiv651').style.width = '0'

        const self = this
        let i = 0, a4 = 0, a5 = 0, resMulti = [], len = 0
        if (this.isShowMulti) {
          console.log('::', this.dataMultiAsk)
          len = this.dataMultiAsk.length
          console.log(';;', len)
        }
        let result = [], percentages = []
        function us2() {
          bv11 = self.getRandomNumber(20, 90);
          bv21 = self.getRandomNumber(20, 90);
          bv31 = self.getRandomNumber(self.truth, 95);
          bv110 = self.getRandomNumber(0, 100);
          bv210 = self.getRandomNumber(0, 100);
          bv310 = self.getRandomNumber(0, 100);
          if (self.isShowMulti) {
            for (let i = 2; i <= len; i++) {
              self.shuffle(resMulti)
              resMulti.push(self.getRandomNumber(20, 90));
            }
          }
          const znam =  70 //bv11 - bv21 > 0 ? bv11 : bv21

          a4 = +(Math.abs(bv11 - bv21) * 100 / znam).toFixed(0)
          const mult = window.innerWidth < 526 ? 1 : 2
          if (document.getElementById('apDiv541') && document.getElementById('apDiv651')) {
            document.getElementById('apDiv541').style.width = bv110 * mult + 'px'
            document.getElementById('apDiv551').style.width = bv210 * mult + 'px'
            document.getElementById('apDiv651').style.width = bv310 * mult + 'px'
          }
          self.c1 = bv11
          self.c2 = bv21
          result = [bv11, bv21, bv31, a4]
          if (self.isShowMulti) {
            percentages = [...result.slice(0, 2), ...resMulti]
          }
          self.$store.dispatch('resultValue', result)
          self.$store.dispatch('percentages', percentages)
        }
          this.tim1 = setInterval(us2, 100)
          // document.getElementById('apDiv43').style.opacity = 0
      },
      
      showFish() {
        $('#apDiv45').animate({opacity: "0"}, 6000).animate({opacity: "0.3"}, 6000)
        $('#apDiv44').animate({opacity: "0"}, 6000).animate({opacity: "0.7"}, 6000)
        $('#apDiv28').animate({opacity: "0"}, 6000).animate({opacity: "0.3"}, 6000)
      },
    },
  }
</script>

<style scoped>
  #apDiv410 {
    position: absolute;
    width: 100vw;
    opacity: 0.1;
    left:0;
    rotate:180deg;
  }
/*
  .loading{
    transition:width 3s ease;
  }
*/
/*
  .pos-multiAsk{
    position:absolute;
    top:5px;
    overflow:scroll;
    height:45px;
    scrollbar-width:none;
  }
*/
/*
  .tb-ask {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80px;
    border: 1px solid rgb(0, 0, 0);
    font-size: 0.67rem;
    padding: 1.5px 5px;
    margin-top: 2px;
  }
*/
/*
  #apDiv74{
    left:7px;
  }
*/
/*
  #apDiv42{
    right:0;
    width:275px;
  }
*/
/*
  #apDiv44{
    border:1px solid;
    !*background-image: url("../../public/img/mag2.gif");*!
  }
*/
</style>