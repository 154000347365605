<template>
  <div class="donate-page">
    <h1>Поддержите нас</h1>
    <p>Ваша поддержка помогает нам развиваться и предоставлять лучшие сервисы.</p>
    <input v-model="donationAmount" type="number" placeholder="Введите сумму пожертвования" />
    <div id="paypal-button-container"></div>
    <button @click="handleDonation">Сделать пожертвование</button>
  </div>
</template>

<script>
  import { loadScript } from '@paypal/paypal-js';

  export default {
    name: 'DonatePage',
    data() {
      return {
        donationAmount: 0 // Изначальная сумма пожертвования
      };
    },
    methods: {
      async handleDonation() {
        if (this.donationAmount > 0) {
          await loadScript({ "client-id": "AWkB0caN-W6pfv-DH4lADDE0Ddws5HOz0x3Eu6TmrBYeyMXlY2ezqs893yudlzhET2z06uy4UIjmIYJk" });
          // await loadScript({ "client-id": "your-client-id" });
          paypal.Buttons({
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    value: this.donationAmount.toFixed(2), // Используйте введенную сумму пожертвования
                    currency_code: 'USD' // Установите нужную валюту
                  }
                }]
              });
            },
            onApprove: (data, actions) => {
              return actions.order.capture().then((details) => {
                console.log('Payment completed:', details);
                alert('Спасибо за ваше пожертвование!');
              });
            }
          }).render('#paypal-button-container');
        } else {
          alert('Пожалуйста, введите сумму больше 0');
        }
      }
    },
    mounted() {
      this.handleDonation(); // Вызовите метод для рендера кнопки PayPal при монтировании компонента
    }
  }
</script>

<style scoped>
  .donate-page {
    text-align: center;
    padding: 20px;
  }
  
  .donate-page input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
  }
  
  .donate-page button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .donate-page button:hover {
    background-color: #45a049;
  }
</style>


<!--
<template>
  <div class="donate-page">
    <h1>Поддержите нас</h1>
    <p>Ваша поддержка помогает нам развиваться и предоставлять лучшие сервисы.</p>
    <input v-model="donationAmount" type="number" placeholder="Введите сумму пожертвования" />
    <div id="paypal-button-container"></div>
    <button @click="handleDonation">Сделать пожертвование</button>
  </div>
</template>
<script>
  import { loadScript } from '@paypal/paypal-js';

  export default {
    name: 'DonatePage',
    data() {
      return {
        donationAmount: 0 // Изначальная сумма пожертвования
      };
    },
    methods: {
      async handleDonation() {
        if (this.donationAmount > 0) {
          await loadScript({ "client-id": "AWkB0caN-W6pfv-DH4lADDE0Ddws5HOz0x3Eu6TmrBYeyMXlY2ezqs893yudlzhET2z06uy4UIjmIYJk" });
          // loadScript('https://www.paypal.com/sdk/js?client-id=AWkB0caN-W6pfv-DH4lADDE0Ddws5HOz0x3Eu6TmrBYeyMXlY2ezqs893yudlzhET2z06uy4UIjmIYJk')
          paypal.Buttons({
            createOrder: function(data, actions) {
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    value: this.donationAmount.toFixed(2), // Используйте введенную сумму пожертвования
                    currency_code: 'USD' // Установите нужную валюту
                  }
                }]
              });
            },
            onApprove: function(data, actions) {
              return actions.order.capture().then(function(details) {
                console.log('Payment completed:', details);
                alert('Спасибо за ваше пожертвование!');
              });
            }
          }).render('#paypal-button-container');
        } else {
          alert('Пожалуйста, введите сумму больше 0');
        }
      }
    },
    mounted() {
      this.handleDonation(); // Вызовите метод для рендера кнопки PayPal при монтировании компонента
    }
  }
</script>
<style scoped>
  .donate-page {
    text-align: center;
    padding: 20px;
  }
  
  .donate-page input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
  }
  
  .donate-page button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .donate-page button:hover {
    background-color: #45a049;
  }
</style>
-->


<!--
<template>
  <div class="donate-page">
    <h1>Поддержите нас</h1>
    <p>Ваша поддержка помогает нам развиваться и предоставлять лучшие сервисы.</p>
    <button @click="handleDonation">Сделать пожертвование</button>
  </div>
</template>
<script>
  import { loadScript } from '@paypal/paypal-js';

  export default {
    name: 'DonatePage',
    methods: {
      handleDonation() {
        loadScript('https://www.paypal.com/sdk/js?client-id=AWkB0caN-W6pfv-DH4lADDE0Ddws5HOz0x3Eu6TmrBYeyMXlY2ezqs893yudlzhET2z06uy4UIjmIYJk')
        // loadScript('https://www.paypal.com/sdk/js?client-id=your-client-id')
          .then(() => {
            paypal.Buttons({
              createOrder: function(data, actions) {
                return actions.order.create({
                  amount: {
                    total: '10.00', // Установите нужную сумму
                    currency: 'USD', // Установите нужную валюту
                  },
                });
              },
              onApprove: function(data, actions) {
                return actions.order.capture().then(function(details) {
                  console.log('Payment completed:', details);
                });
              },
            }).render('#paypal-button-container');
          })
          .catch((error) => {
            console.error('Error loading PayPal SDK:', error);
          });
      }
    }
  }
</script>
<style scoped>
  .donate-page {
    text-align: center;
    padding: 20px;
  }
  
  .donate-page button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .donate-page button:hover {
    background-color: #45a049;
  }
</style>
-->


<!--
<template>
  <div class="donate-page">
    <h1>Поддержите нас</h1>
    <p>Ваша поддержка помогает нам развиваться и предоставлять лучшие сервисы.</p>
    <input v-model="donationAmount" type="number" placeholder="Введите сумму пожертвования" />
    <button @click="handleDonation">Сделать пожертвование</button>
  </div>
</template>
<script>
  import { PayPalButton } from '@paypal/checkout-web-sdk';

  export default {
    name: 'DonatePage',
    data() {
      return {
        donationAmount: 0 // Изначальная сумма пожертвования
      };
    },
    methods: {
      handleDonation() {
        if (this.donationAmount > 0) {
          PayPalButton({
            clientID: 'your-client-id', // Вставьте ваш Client ID от PayPal
            environment: 'sandbox', // Используйте 'live' для продакшн-среды
            commit: true,
            payment: {
              transactions: [{
                amount: {
                  total: this.donationAmount.toFixed(2), // Используйте введенную сумму пожертвования
                  currency: 'USD' // Установите нужную валюту
                }
              }]
            },
            onApprove: async (data, actions) => {
              try {
                const payment = await actions.payment.execute();
                console.log('Payment completed:', payment);
              } catch (error) {
                console.error('Error processing payment:', error);
              }
            }
          }).render('#paypal-button-container');
        } else {
          alert('Пожалуйста, введите сумму больше 0');
        }
      }
    }
  }
</script>
<style scoped>
  .donate-page {
    text-align: center;
    padding: 20px;
  }
  
  .donate-page input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
  }
  
  .donate-page button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .donate-page button:hover {
    background-color: #45a049;
  }
</style>
-->
<!--
<template>
  <div class="donate-page">
    <h1>Поддержите нас</h1>
    <p>Ваша поддержка помогает нам развиваться и предоставлять лучшие сервисы.</p>
    <button @click="handleDonation()">Сделать пожертвование</button>
  </div>
</template>
<script>
  export default {
    name: 'DonatePage',
    methods: {
/*
      handleDonation() {
        this.$router.push('/donate');
      }
*/
    }
  }
</script>
<style scoped>
  .donate-page {
    text-align: center;
    padding: 20px;
  }
  
  .donate-page button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .donate-page button:hover {
    background-color: #45a049;
  }
</style>
-->
