<template>
  <div v-cloak>
  <AppLayout id='cont' class="content home-background1">
    <div class="sec0" >
      <div id="apDiv108" @click="info1()" class="info1"> i</div>
      <div id="apDiv26">
        <div class="text-info1" :class="{ 'text-info-de': lng === 'de' }">
          <u style="margin-bottom:10px">{{intFace[7]}}</u>
          <div class="txt-inf">
            {{intFace[65]}}
          </div>
        </div>
        <div class="pos-close1" @click="multiAsk(2)">x</div>
      </div>
  
      <div class="wrapper-window" v-if="isWrapper" @click="closeInfo()"></div>
      <div id="wrap85" class="wrap85 cursor" @click="about(1)">
        <div
          id="apDiv85"
          class="zg5"
          :data-title="intFace[2]"
        >
          <div id="apDiv95">
            <!--<h4>{{intFace[3]}}</h4>-->
            <h4>{{intFace[66]}}</h4>
          </div>
          <div id="apDiv92">
            <img src="img/str.png" class="str" alt=''>
            <!--<img :src = "require('@/assets/img/str.png')" width="22" height="23" alt=''>-->
          </div>
        </div>
      </div>
      <div id='apDiv81'>
        <div class="menu" :class="{ 'menu-de': lng === 'de' }">
          <div id="apDiv96">
            <h4>{{intFace[66]}}</h4>
            <!--<h4>{{intFace[13]}}</h4>-->
          </div>
          <div class="menu-item" @click="aboutProg(1)">
            <img src="../assets/icons/contacts.svg" alt="Контакты" class="icon">
            {{intFace[67]}}
          </div>
          <div class="menu-item" @click="aboutProg(2)">
            <img src="../assets/icons/settings.svg" alt="Настройки" class="icon">
            {{intFace[68]}}
          </div>
          <div class="menu-item" @click="aboutProg(3)">
            <img src="../assets/icons/info.svg" alt="О программе" class="icon">
            {{intFace[69]}}
          </div>
          <!--<div class="menu-item" @click="aboutProg(4)">-->
          <div v-if="isScreen320" class="menu-item" @click="aboutProg(4)">
            <img src="../assets/icons/process.svg" alt="Порядок работы" class="icon">
            {{intFace[13]}}
          </div>
          <div class="menu-item" @click="aboutProg(5)">
            <!--@click="handleDonation()-->
            <img src="../assets/icons/favorite.svg" alt="Донаты" class="icon">
            {{intFace[70]}}
          </div>
        </div>
    
        <div
          id="apDiv110"
          class="zg51 cursor"
          :data-title="intFace[12]"
          @click="about(2)"
        >
        </div>
<!--
        <div id="apDiv94">
          <a href="#"><img :src = "require('@/assets/img/str_v.png')" width="22" height="23" alt=''></a>
          &lt;!&ndash;<img :src = "require('@/assets/img/str_v.png')" width="22" height="23" alt=''>&ndash;&gt;
          &lt;!&ndash;<a href="#"><img src="img/str_v.png" width="22" height="23" alt=''></a>&ndash;&gt;
        </div>
-->
<!--
        <div id="apDiv96">
          <h4>Меню</h4>
          &lt;!&ndash;<h4>{{intFace[13]}}</h4>&ndash;&gt;
        </div>
-->
        <div id="apDiv88">
          <div v-if="showMenu === 1">
            <h2>{{intFace[67]}}</h2>
            <div class="info-container">
              <div class="contact">
                <!--<p>E-mail: <a href="mailto:intFace[81]">{{intFace[81]}}</a></p>-->
                <p>E-mail:
                  <a v-if="lng === 'ru'"
                      href="mailto:esochru@gmail.com"
                  >esochru@gmail.com</a>
                  <a v-else
                     href="mailto:ezochate@gmail.com"
                  >ezochate@gmail.com</a>
                </p>
              </div>
              <div class="youtube">
                <p>{{intFace[71]}}<br/>
                  <a v-if="lng === 'ru'"
                     href="https://www.youtube.com/@vlsegal"
                     target="_blank">{{intFace[83]}}
                  </a>
                  <a v-else
                     href="https://www.youtube.com/@vlseg-eso"
                     target="_blank">{{intFace[83]}}
                  </a>
                </p>
                <!--<p>{{intFace[71]}}<br/> <a href="https://www.youtube.com/@vlsegal/videos" target="_blank">youtube.com/@vlsegal/videos</a></p>-->
              </div>
              <div class="video">
                <p>{{intFace[72]}}<br/>
                <div v-if="lng === 'ru'">
                  <a href="https://www.youtube.com/watch?v=slVmTUOlf9U"
                    target="_blank"
                  >{{intFace[82]}} (10мин)
                  </a><br/>
                  <a href="https://youtube.com/shorts/hTRlj82-a44?feature=share"
                    target="_blank"
                  >{{intFace[84]}} (1мин)
                  </a>
                </div>
                <div v-else>
                  <a href="https://youtu.be/w_mobmMik5w"
                    target="_blank"
                  >{{intFace[82]}} (10min)
                  </a><br/>
                  <a href="https://youtube.com/shorts/YxIK1SzBm3c?feature=share"
                    target="_blank"
                  >{{intFace[84]}} (1min)
                  </a>
                </div>
  
                </p>
              </div>
            </div>
          </div>
          <div v-if="showMenu === 2">
            <h2>{{intFace[68]}}</h2>
            <div class="row">
              <h3>{{intFace[74]}}</h3>
              <div id="lengv">{{esteb}}
                <select v-model="selectedLanguage" id="language-select" @change="changeLanguage">
                  <option v-for="language in languages" :key="language.value" :value="language.value">
                    {{ language.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div v-if="showMenu === 3">
            <h2>{{intFace[69]}}</h2>
            <div class="about">
              {{intFace[75]}} 2.2.<br/>
              {{intFace[76]}}
              <div class="design">{{intFace[77]}}</div>
            </div>
          </div>
          <div v-if="showMenu === 4">
            <h2>{{intFace[13]}}</h2>
            <div class="wrap-work">
              <p>{{intFace[14]}}
                <router-link to="/about">{{intFace[15]}}</router-link>
                {{intFace[16]}}
              </p>
            </div>
            <!--
                        <h5
                          :class="{ 'inf-text-de': lng === 'de' }"
                        >{{intFace[17]}}</h5>
            -->
          </div>
          <div v-if="showMenu === 5">
<!--
          <h2>Донаты</h2>
          <h1 style="margin-top: 6px;">Поддержите нас</h1>
          <p style="margin-bottom: 15px;">Ваша поддержка помогает нам развиваться и предоставлять лучшие сервисы.</p>
          <input v-model="donationAmount" type="number" placeholder="0" />
          <button :disabled="ationAmount > 0)" @click="handleDonation">Пожертвовать</button>

<!--
          &lt;!&ndash; Контейнер для кнопки PayPal &ndash;&gt;
          <div id="paypal-button-container"></div>

          &lt;!&ndash; Модальное окно для формы Debit or Credit Card &ndash;&gt;
          <div v-if="showModal" class="modal">
            <div class="modal-content">
              <span class="close" @click="closeModal">&times;</span>
              <div id="modal-paypal-button-container"></div>
            </div>
          </div>
-->
            
            <h2>{{intFace[70]}}</h2>
            <h1 style="margin-top: 6px;">{{intFace[78]}}</h1>
            <p style="margin-bottom: 15px;">{{intFace[79]}}</p>
            <!-- Модальное окно для формы Debit or Credit Card -->
            <div v-if="showModal" class="modal">
              <div class="modal-content">
                <span class="close" @click="closeModal">&times;</span>
                <div id="modal-paypal-button-container"></div>
              </div>
            </div>
            <div id="paypal-button-container" class="paypal-button-container" v-if="isPayPal"></div>
            <div class="row" v-else>
              <h3>PayPal: </h3>
              <p><a href="https://www.paypal.com/donate/?hosted_button_id=FXYM5TY4EBBHW">{{intFace[80]}}</a></p>
<!--
              <input v-model="donationAmount" type="number" placeholder="0" />
              <button :disabled="!(donationAmount > 0)" @click="handleDonation">{{intFace[80]}}</button>
-->
            </div>
          </div>
          <div class="pos-close2" @click="menuclose()">x</div>
        </div>
      </div>
  
      <div id="apDiv12">
        <div id="apDiv105">{{intFace[0]}}</div>
        <div class="wrapper">
          <div id="apDiv3">{{intFace[1]}}</div>
<!--
          <div id="wrap85" class="wrap85 cursor" @click="about(1)">
            <div
              id="apDiv85"
              class="zg5"
              :data-title="intFace[2]"
            >
              <div id="apDiv95">
                &lt;!&ndash;<h4>{{intFace[3]}}</h4>&ndash;&gt;
                <h4>Меню</h4>
              </div>
              <div id="apDiv92">
                <img src="img/str.png" class="str" alt=''>
                &lt;!&ndash;<img :src = "require('@/assets/img/str.png')" width="22" height="23" alt=''>&ndash;&gt;
              </div>
            </div>
          </div>
-->
        </div>
        <div class="toggleInfo" :class="{ 'pos-toggl-en': lng === 'en' }">
          <div id="apDiv25" class="info">
          <!--<div id="apDiv25" class="info" :class="{ 'pos25-en': lng === 'en' }">-->
            <div class="text-info" :class="{ 'text-info-de': lng === 'de' }">
              <u style="margin-bottom:10px">{{intFace[7]}}</u>
              {{intFace[8]}}
            </div>
            <div class="pos-close" @click="multiAsk(1)">x</div>
          </div>
          <div id='apDiv8'>
            <div
              id="apDiv107"
              class="info"
              @click="info()"
            > i
            </div>
            <h2 id="ndt1234">{{isCheckboxChecked1 ?  intFace[62] : intFace[4]}}</h2>
            <h5><textarea
              name="qv1" cols="25" id="qv1"
              :placeholder="isCheckboxChecked1 ? '' : intFace[59]"
              class="scroll"
              :class="{ 'bg-area': isCheckboxChecked1 }"
              v-model="textInput"
              :disabled="isCheckboxChecked1"
              :value="isCheckboxChecked1 ? intFace[60] : textInput"
            ></textarea>
            </h5>
            <div class="row-check">
              <div class="check1">
                <input type="checkbox" id="checkbox1" v-model="isCheckboxChecked1" @click="ponder">
                <label for="checkbox1" class="lab-check">{{intFace[63]}}</label>
              </div>
              <div class="check2">
                <input type="checkbox" id="checkbox" v-model="isCheckboxChecked">
                <label for="checkbox" class="lab-check">{{intFace[57]}}</label>
              </div>
            </div>
            <!--<div class="wr" v-if="isButtonDisabled"></div>-->
            <button id="apDiv2"
              :disabled="isButtonDisabled"
              @click="choose"
              :class="{ 'butt-disabl': isButtonDisabled }"
            >{{intFace[6]}}</button>
            <!--<div>-->
              <!--<div class="wr" v-if="isButtonDisabled1"></div>-->
              <!--<button id="apDiv2" @click="choose">{{intFace[6]}}</button>-->
            <!--</div>-->
          </div>
          <div id="apDiv23">
            <p id='vrn'
              style="margin:10px 0"
              :class="{ 'vrn-de': lng === 'de' }"
            >{{intFace[9]}}</p>
            <div class="grid-container">
              <div v-for="row in 3" :key="row" class="pos-hr">
                <div v-for="col in 2" :key="col" class="pos-vr-container">
                  <span class="pos-vr">{{ (row - 1) * 2 + col }}</span>
                  <input
                    :name="'ot' + ((row - 1) * 3 + col)"
                    type="text"
                    :id="'ot' + ((row - 1) * 3 + col)"
                    v-model="dataMultiAsk[(row - 1) * 3 + col - 1]"
                    size="6"
                    class="overflow"
                    @input="checkFields"
                    @change="handleFocus($event)"
                    @keyup.enter="handleFocus($event)"
                  >
                </div>
              </div>
            </div>
            <div id="apDiv78">
              <input type="submit" name="kn3" id="kn3" @click="multiAsk(1)" :value="intFace[55]" style="margin-right:10px">
              <input
                type="submit" name="kn1" id="kn1" @click="multiAskEnter(5)" :value="intFace[56]" style="margin-right:10px"
              >
              <input
                type="submit"
                name="kn2"
                id="kn2"
                @click="multiAskEnter(6)"
                :value="intFace[10]"
                :disabled="isDisabledMulti"
              >
            </div>
          </div>
        </div>
      </div>
      <div id="apDiv86" :class="{ 'text86-en': lng === 'en' }">
        <div id="apDiv93">
          <h4><strong>{{intFace[11]}}</strong></h4>
          <p id="sub-txt" :class="{ 'sub-txt-en': lng === 'en' }">{{intFace[14]}}
            <router-link to="/about">{{intFace[15]}}</router-link>
            {{intFace[16]}}
          </p>
          <h5>{{intFace[17]}}</h5>
        </div>
<!--
        <div id='apDiv81'>
  
          <div class="menu">
            <div class="menu-item">
              <i class="fas fa-address-book"></i>
              Контакты
            </div>
            <div class="menu-item">
              <i class="fas fa-cogs"></i>
              Настройки
            </div>
            <div class="menu-item">
              <i class="fas fa-info-circle"></i>
              О программе
            </div>
            <div class="menu-item">
              <i class="fas fa-donate"></i>
              Донаты
            </div>
          </div>
          
          <div
            id="apDiv110"
            class="zg51 cursor"
            :data-title="intFace[12]"
            @click="about(2)"
          >
          </div>
          <div id="apDiv94">
            <a href="#"><img :src = "require('@/assets/img/str_v.png')" width="22" height="23" alt=''></a>
            &lt;!&ndash;<img :src = "require('@/assets/img/str_v.png')" width="22" height="23" alt=''>&ndash;&gt;
            &lt;!&ndash;<a href="#"><img src="img/str_v.png" width="22" height="23" alt=''></a>&ndash;&gt;
          </div>
          <div id="apDiv96">
            <h4>Меню</h4>
            &lt;!&ndash;<h4>{{intFace[13]}}</h4>&ndash;&gt;
          </div>
          <div id="apDiv88">
            <p>{{intFace[14]}}
              <router-link to="/about">{{intFace[15]}}</router-link>
              {{intFace[16]}}
            </p>
            <h5
              :class="{ 'inf-text-de': lng === 'de' }"
            >{{intFace[17]}}</h5>
          </div>
        </div>
-->
        <div id="apDiv89">
          <div class="img"></div>
          <div class="wrapp"></div>
          <div id='plt'>
<!--
&lt;!&ndash;-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&ndash;&gt;
            <div id="lengv" style="position:absolute">{{esteb}}
              <select v-model="selectedLanguage" id="language-select" @change="changeLanguage">
                <option v-for="language in languages" :key="language.value" :value="language.value">
                  {{ language.text }}
                </option>
              </select>
            </div>
-->
            
            <!--
                                    <div id="apDiv26">
                                      <div class="text-info" :class="{ 'text-info-de': lng === 'de' }">
                                        <u style="margin-bottom:10px">{{intFace[7]}}</u>
                                        {{intFace[8]}}
                                      </div>
                                      <div class="pos-close" @click="multiAsk(2)">x</div>
                                    </div>
                        -->
            
            <div id="apDiv131" style="display:block">
              <div id="wrap" class="wrap">
                <img id="mag" class='sol1' :src="url" alt=''>
                <div id="set" class="settings">
                  <div class="level">{{intFace[42]}}: {{levelAnswer}}</div>
                  <span class="title">{{intFace[43]}}<br> {{intFace[44]}}</span>
                  <p class="pos-value">{{truth}} </p>
                </div>
              </div>
              <div id="stamp">
                <img class='sol11' :src="urlStamp" alt=''>
                <!--<img class='sol11' :src="require('@/assets/img/stamp_yes_ru.jpg')" alt=''>-->
                <!--<img class='sol11' :src="require('@/assets/img/stamp_' + esteb + '_ru.jpg')" alt=''>-->
                <!--:src = "require('@/assets/img/ramk5.png')"-->
                <!--<img class='sol11' :src="urlStamp" alt=''>-->
                <div id="dial" v-if="isEsteblish"
                  class="dial"
                  :class="lng === 'de' ? 'sub-de' : lng === 'en' ? 'sub-en' : 'sub-ru'"
                >
                {{intFace[45]}}<br>{{intFace[46]}}
                </div>
                <div id="txt" v-else
                  class="dial-no"
                  :class="lng === 'ru' ? 'sub-no-ru' : lng === 'en' ? 'sub-no-en' : 'sub-no-de'"
                >
                  {{ nameHelper }} {{intFace[47]}}<br>{{intFace[48]}}
                </div>
              </div>
              <div id="apDiv149t" class="zg147"
               :class="lng === 'ru' ? 'pos-ru' : lng === 'en' ? 'pos-en' : 'pos-de'"
              >
               {{intFace[49]}} {{ nameHelper }}
                <br/>{{intFace[50]}}
              </div>
              <button
                id="buttEdit"
                @click="chooseMag(true, true)"
                v-if="numAsk >= 0"
                class="edit-helper"
              >&#9998;
                <span
                  class="tooltiptext"
                  :class="{ 'text-de': lng === 'de' }"
              >{{intFace[51]}}</span></button>
            
            </div>
            <!--src="img/ramk5.png"-->
            <img
              :src = "require('@/assets/img/ramk5.png')"
              class="fon-img"
              alt=""
              data-title="Закончить тренировку"
            >
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
  </div>
</template>

<script>
  import $ from 'jquery'
  import AppLayout from '../layout/AppLayout'
  import { loadScript } from '@paypal/paypal-js';
  // import urlStamp1 from '@/assets/img/stamp_yes_ru.jpg'
  // import stampYesRu from '@/assets/img/stamp_yes_ru.jpg';
  
/*
  const aa = 'русский'
  console.log('!!', lng)
*/
  
  export default {
    name: 'Home',
    components: {AppLayout},
    data() {
      return {
        textInput: '',
        isScreen320: null,
        selectedFruits: [],
        isCheckboxChecked: false,
        isCheckboxChecked1: false,
        questionWordsRu: ["кто", "кого", "кому", "когда", "кем", "что", "чего", "чему", "чем", "отчего",
          "какая", "какие", "какому", "какой", "какое", "какого", "каких", "каким", "каков", "какова",
          "каковы", "о каких", "о каком", "какую", "чей", "чья", "чьей", "чью", "о чьей", "чьи", "чьего",
          "чьему", "о чьем", "чьих", "чьим", "о чьих", "чьими", "который", "которая", "которые", "которого",
          "сколько", "скольких", "скольким", "сколькими", "где", "куда", "зачем", "откуда", "как", "почему",
          "о ком", "о чем", "каково", "каковой", "каковы", "отчего", "или"],
        questionWordsEn: ["who", "whom", "to whom", "when", "by whom", "what", "of which", "whose", "about which",
          "of", "about", "how many", "where", "where to", "wherefore", "why", "wherefrom", "how",
          "about whom", "about what", "whatsoever", "which", "which are", "of what", "from what", "what kind",
          "what time", "how much", "how long", "how often","how far","or"
        ],
        questionWordsDe: ["wer", "wen", "wem", "an wen", "wann", "von wem", "was", "welche", "deren",
          "über deren", "dessen", "über dessen", "wie viele", "wo", "wohin", "warum", "von wo",
          "wie", "über wen", "von was", "wessen", "wozu", "wie viel", "wie lange", "woher", "wie oft", "oder"
        ],
        selectedLanguage: '',
        languages: [
          { value: 'ru', text: 'Русский' },
          { value: 'en', text: 'English' },
          { value: 'de', text: 'Deutsch' }
        ],
        dataMultiAsk: Array(9).fill(''),
        isButtonDisabled: true,
        isButtonDisabled1: false,
        isWrapper: false,
        isDisabledMulti: true,
        check1Txt: '',
        lng: '',
        intFace: [],
        interface: [],
        level: null,
        isMultiAsk: false,
        numAsk: null,
        nameHelper: '',
        url: '',
        setUrl: '',
        urlStamp: '',
        esteb: "",
        // esteb: "require('@/assets/img/stamp_yes_ru.jpg')",
        // urlStamp1: '',
        myMag: '',
        donationAmount: 0,
        isPayPal: false,
        showModal: false,
        truth: null,
        clarity: null,
        isEsteblish: null,
        newImages1: [],
        images: ['/img/svitok_fon1.jpg'],
        levelAnswer: null,
        showMenu: null,
        urlHelpers: [
          {url: 'mag1.gif', name: 'Angeline'},
          {url: 'mag2.gif', name: 'Archer'},
          {url: 'mag3.gif', name: 'Seraphin'},
          {url: 'mag5.gif', name: 'Gable'},
          {url: 'mag6.gif', name: 'Michelin'},
          {url: 'mag70.gif', name: 'Selaphin'},
          {url: 'mag8.gif', name: 'Uriah'},
          {url: 'mag10.gif', name: 'Jegudian'},
          {url: 'mag12.gif', name: 'Cherubin'},
          {url: 'mag14.gif', name: 'Seraphin'},
          {url: 'mag15.gif', name: 'Archer'},
          {url: 'mag17.gif', name: 'Raphel'},
          {url: 'mag18.gif', name: 'Selaphin'},
          {url: 'mag72.gif', name: 'Raphel'},
          {url: 'mag9.gif', name: 'Selaphin'},
          {url: 'mag19.gif', name: 'Gable'},
          // {url: 'mag11.gif', name: 'Angeline'},   //todo разм.
        ],
      }
    },
    created() {
      if (!localStorage.getItem('lng')) {
        const getBrowserLanguage = () => {
          const language = navigator.language || navigator.userLanguage
          return language.split('-')[0]
        }
        let lng = getBrowserLanguage()
        this.selectedLanguage = lng
        this.lg = (lng === 'ru') ? 1 : (lng === 'en') ? 2 : (lng === 'de') ? 3 : 0;
        import("../data/interface.js")
          .then(module => {
            this.interface = module.intface
            localStorage.setItem('interface', JSON.stringify(this.interface))
            this.changeLanguage()
          })
          .catch(err => {
            console.error('Ошибка при динамическом импорте:', err)
          })
      } else {
        this.lng = localStorage.getItem('lng');
        this.selectedLanguage = this.lng;
        this.intFace = JSON.parse(localStorage.getItem('intFace'))
      }

/*
      if (localStorage.getItem('numAsk')) {
        this.numAsk = +localStorage.getItem('numAsk')
      } else {
        localStorage.setItem('numAsk', this.numAsk)
      }
      if (localStorage.getItem('pageRebootHome') === 'true') {
        this.reboot()
      } else {
        localStorage.setItem('pageRebootHome', 'true')
        this.chooseMag(false, true)
      }
*/

      // this.preloadImage(estebl, this.lng);
      
      /*
            const img = new Image()
            // img.src = '/img/svitki_09.png'
            img.src = "require('@/assets/img/svitki_09.png')"
            img.onload = () => {
              localStorage.setItem('backgroundImage', '/img/svitki_09.png')
            }
      */
      this.asks()
    },
    async mounted() {
      this.isScreen320 = window.innerWidth < 526
      if (this.isScreen320) {
        const widthDiv3 = window.innerWidth < 430 ? '44' : '60'
        $('#apDiv3').css('width', `${widthDiv3}%`)
      }
      // this.lng = localStorage.getItem('lng');
      // console.log('-->', this.lng)
      if (localStorage.getItem('numAsk')) {
        this.numAsk = +localStorage.getItem('numAsk')
      } else {
        localStorage.setItem('numAsk', this.numAsk)
      }
      if (localStorage.getItem('pageRebootHome') === 'true') {
        this.reboot()
      } else {
        localStorage.setItem('pageRebootHome', 'true')
        this.chooseMag(false, true)
      }

      // this.preloadImage();
    },
    computed: {
      isButtonDisabled() {
        return (!this.isCheckboxChecked1 && this.textInput.trim() === '') || this.isButtonDisabled1;
      }
    },
    watch: {
      isButtonDisabled() {
        this.isButtonDisabled = true
      },
      textInput(newVal) {
        this.checkText(newVal);
      }
    },
    methods: {

/*
      async handleDonation() {
        if (this.donationAmount > 0) {
          await loadScript({ "client-id": "AWkB0caN-W6pfv-DH4lADDE0Ddws5HOz0x3Eu6TmrBYeyMXlY2ezqs893yudlzhET2z06uy4UIjmIYJk" });
          paypal.Buttons({
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    value: this.donationAmount.toFixed(2), // Используйте введенную сумму пожертвования
                    currency_code: 'USD' // Установите нужную валюту
                  }
                }]
              });
            },
            onApprove: (data, actions) => {
              return actions.order.capture().then((details) => {
                console.log('Payment completed:', details);
                alert('Спасибо за ваше пожертвование!');
                this.closeModal(); // Закрыть модальное окно после успешной оплаты
              });
            },
            onClick: (data, actions) => {
              this.showModal = true; // Открыть модальное окно при клике
              return actions.resolve();
            }
          }).render('#paypal-button-container');
        } else {
          alert('Пожалуйста, введите сумму больше 0');
        }
      },
*/
      
      async handleDonation() {
        this.isPayPal = true
        if (this.donationAmount > 0) {
          await loadScript({ "client-id": "AWkB0caN-W6pfv-DH4lADDE0Ddws5HOz0x3Eu6TmrBYeyMXlY2ezqs893yudlzhET2z06uy4UIjmIYJk" });
          paypal.Buttons({
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    value: this.donationAmount.toFixed(2), // Используйте введенную сумму пожертвования
                    currency_code: 'USD' // Установите нужную валюту
                  }
                }]
              });
            },
            onApprove: (data, actions) => {
              return actions.order.capture().then((details) => {
                console.log('Payment completed:', details);
                alert('Спасибо за ваше пожертвование!');
              });
            },
            onClick: (data, actions) => {
              // $('.paypal-button-container').css('position', 'absolute');
              $('#apDiv81').css({
                'background-size': 'cover',
                'height': '100vh',
                'top': '-14px'
              });
              // $('#apDiv94').hide();
              $('#apDiv88').css('height', '73%');

/*
              // Используем MutationObserver для отслеживания изменений в DOM
              const observer = new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                  if (mutation.addedNodes.length) {
                    $(mutation.addedNodes).each((index, node) => {
                      console.log('-> Проверка узла:', node);
                      if ($(node).find('button:contains("✕")').length) {
                        console.log('-> Крестик найден!');
                        observer.disconnect();
                        $('button:contains("✕")').click(() => {
                          alert(1);
                          let a = 1;
                          console.log('Переменная a присвоена значению:', a);
                          this.closeModal(); // Закрыть модальное окно
                        });
                      }
                    });
                  }
                });
              });
              observer.observe(document.body, { childList: true, subtree: true });
*/

              return actions.resolve();
            }
          }).render('#paypal-button-container');
        } else {
          alert('Пожалуйста, введите сумму больше 0');
        }
      },
      closeModal() {
        alert(2)
      },
      
/*
      async handleDonation() {
        this.isPayPal = true
        if (this.donationAmount > 0) {
          await loadScript({ "client-id": "AWkB0caN-W6pfv-DH4lADDE0Ddws5HOz0x3Eu6TmrBYeyMXlY2ezqs893yudlzhET2z06uy4UIjmIYJk" });
          // await loadScript({ "client-id": "your-client-id" });
          paypal.Buttons({
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    value: this.donationAmount.toFixed(2), // Используйте введенную сумму пожертвования
                    currency_code: 'USD' // Установите нужную валюту
                  }
                }]
              });
            },
            onApprove: (data, actions) => {
              return actions.order.capture().then((details) => {
                console.log('Payment completed:', details);
                alert('Спасибо за ваше пожертвование!');
              });
            }
          }).render('#paypal-button-container');
        } else {
          alert('Пожалуйста, введите сумму больше 0');
        }
      },
*/

/*
      handleDonation() {
        this.$router.push('/donate');
      },
*/
      menuclose() {
        $('#apDiv88').css('height', '66%');
        $('#apDiv88').hide(600)
        $('.menu').show(600)
        this.isPayPal = false
        this.donationAmount = 0

        $('.paypal-button-container').css('position', 'relative');
        $('#apDiv81').css({
          'background-size': 'contain',
          'height': '443px',
          'top': '19px'
        });
        // $('#apDiv94').show(600) //.css('left', '263px');

        // $('#apDiv88').css('height', '273px !important');
      },
      aboutProg(num) {
        $('#apDiv88').show(600)
        $('.menu').hide(600)
        this.showMenu = num
/*
        if (num === 1) {
        }
*/
      },
/*
      preloadImage() {
        this.esteb = stampYesRu;
      },
*/
      preloadImage(estebl, lng) {
        const imagePath = `@/assets/img/stamp_${estebl}_${lng}.jpg`;
        this.urlStamp = require(`${imagePath}`);
      },
/*
      ponder() {
        // this.isButtonDisabled = false
        // localStorage.setItem('check', this.isCheckboxChecked1)
        // console.log('::',this.textInput)
/!*
        if (this.isCheckboxChecked1) {
          this.check1Txt = 'VV'
        }
      
*!/
      },
*/
      asks() {
        this.questionWords = (this.lng === 'ru') ? this.questionWordsRu : (this.lng === 'en') ?
          this.questionWordsEn : this.questionWordsDe
/*
        console.log('>',this.intFace,this.intFace[63])
        this.check1Txt = this.intFace[63]
        // this.check1Txt = (this.lng === 'ru') ? this.intFace[63][1] : (this.lng === 'en') ?
        //   this.intFace[63][2] : this.intFace[63][3]
*/

      },
      checkText(text) {
        const words = text.toLowerCase().split(/\s+/);
        this.isCheckboxChecked = this.questionWords.some(word => words.includes(word.toLowerCase()));
      },
      handleFocus(event) {
        const input = event.target
        setTimeout(() => {
          input.setSelectionRange(0, 0)
          input.blur()
        }, 0)
      },
      checkFields() {
        if (this.dataMultiAsk.filter(item => item !== '').length > 1) {
          this.isDisabledMulti = false
          document.getElementById("kn2").style.opacity = '1'
          document.getElementById("kn2").style.cursor = 'pointer'
        } else {
          this.isDisabledMulti = true
          document.getElementById("kn2").style.opacity = '0.5'
          document.getElementById("kn2").style.cursor = 'auto'
        }
      },
      changeLanguage() {
        const bb =!!!localStorage.getItem('lng')
        const lng = this.selectedLanguage
        this.lng = lng
        this.asks()
        localStorage.setItem('lng', lng);
        this.lg = (lng === 'ru') ? 1 : (lng === 'en') ? 2 : (lng === 'de') ? 3 : 0;
        const data = JSON.parse(localStorage.getItem('interface'));
        const interface_ru = data.map(item => item[1]);
        const interface_en = data.map(item => item[2]);
        const interface_de = data.map(item => item[3]);
        localStorage.setItem('interface_ru', JSON.stringify(interface_ru));
        localStorage.setItem('interface_en', JSON.stringify(interface_en));
        localStorage.setItem('interface_de', JSON.stringify(interface_de));
        this.intFace = (lng === 'ru') ? JSON.parse(localStorage.getItem('interface_ru')) :
          (lng === 'en') ? JSON.parse(localStorage.getItem('interface_en')) :
            (lng === 'de') ? JSON.parse(localStorage.getItem('interface_de')) : [];
        localStorage.setItem('intFace', JSON.stringify(this.intFace))
        this.urlStamp = `img/stamp_yes_${this.lng}.jpg`
        // this.urlStamp = `require('@/assets/img/stamp_yes_${this.lng}.jpg')`
        // this.preloadImage(estebl, this.lng);

        console.log('oo',bb)
        if (bb) {
          this.chooseMag(true, false)
        }
      },
      async chooseMag(isEdit, mm) {
        this.isButtonDisabled1 = true
        let estebl = Math.random() < 0.5 ? 'yes' : 'no'
        // this.esteb = estebl
        // this.esteb ="require('@/assets/img/stamp_yes_ru.jpg')"
        // console.log('esteb->',this.esteb)
        let n = 0
        let myMag = localStorage.getItem('myMag')
        console.log('><',!myMag, isEdit, !myMag || isEdit)
        if (!myMag || isEdit) {
          while (n < 3) {
            this.isEsteblish = estebl === 'yes'
            // console.log('this.urlStamp->',`require('@/assets/img/stamp_yes_${this.lng}.jpg')`)
            // this.urlStamp = `require('@/assets/img/stamp_${estebl}_${this.lng}.jpg')`
            // this.urlStamp = `require('@/assets/img/stamp_yes_${this.lng}.jpg`
            this.urlStamp = `img/stamp_${estebl}_${this.lng}.jpg`
            
/*
            console.log('!!', estebl, this.lng)
            this.preloadImage(estebl, this.lng);
*/
            if (mm) this.changeHelper()
            this.loader('#txt', 10, 500)
            this.level = this.getRandomNumber(1, 4)
            if (this.level === 2 || this.level === 3) this.level = Math.random() < 0.5 ? 2 : 3
            this.levelAnswer = this.getLevel(this.level)
            await this.sleep(3800)
            // alert(1)
            if (estebl === 'yes') {
              // $("#apDiv2").css({'cursor': 'pointer', 'opacity': 1})
              // console.log('!!',this.isButtonDisabled, this.textInput, this.textInput.trim() !== '' )
              // if (this.textInput.trim() !== '')
              // this.isButtonDisabled = true //false
              localStorage.setItem('myMag', this.url)
              localStorage.setItem('name', this.nameHelper)
              localStorage.setItem('level', this.level)
              this.isButtonDisabled1 = false
              return
            } else {
              this.isButtonDisabled1 = true
              await this.sleep(1600)
              // if (this.textInput.trim() !== '')  {
              //   $("#apDiv2").css({'cursor': 'pointer', 'opacity': 0.7})
              // }
            }
            estebl = Math.random() < 0.5 ? 'yes' : 'no'
            // this.esteb = estebl
            if (n === 1) estebl = 'yes'
            n++
          }
          // this.isButtonDisabled1 = false //true
        }
      },
      async reboot() {
        // $("#apDiv2").css({'cursor': 'pointer', 'opacity': 1})
        // if (this.textInput.trim() !== '') this.isButtonDisabled = false
        $("#plt").hide()
        $("#cont").hide()
        const imgURL1 = localStorage.getItem('myMag')
        // const imgURL = localStorage.getItem('backgroundImage')
        // const img = new Image()
        const img1 = new Image()
        // img.src = imgURL
        img1.src = imgURL1
        // await img.decode()
        await img1.decode()
        $('#apDiv8').css('background', '/img/images3.jpg').show()
        $("#plt").show()
        $("#cont").show()
        let level = localStorage.getItem('level')
        let url = localStorage.getItem('myMag')
        this.nameHelper = localStorage.getItem('name')
        this.urlStamp = `img/stamp_yes_${this.lng}.jpg`
        this.isEsteblish = true
        this.levelAnswer = this.getLevel(+level)
        this.url = url
        $("#mag").css({'width': '120px', 'height': '109px', 'left': '2px', 'top': '8px'})
        $("#wrap").css({'background': '#e4c8a2'})
      },
      preloadImages(imageUrls) {
        return Promise.all(
          imageUrls.map((url) => {
            return new Promise((resolve, reject) => {
              const img = new Image()
              img.src = url
              img.onload = resolve
              img.onerror = reject
            })
          }),
        )
      },
      getRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min
      },
      loader(selector, times, speed) {
        let count = 0
        function blinker() {
          if (count < times) {
            $(selector).fadeOut(speed, function () {
              $(selector).fadeIn(speed)
              count++
              blinker()
            })
          }
        }
        blinker()
      },
      getLevel(lev) {
        if (lev === 1) {
          this.truth = '> 60%'
          return "I"
        } else if (lev === 2) {
          this.truth = '> 65%'
          return "II"
        } else if (lev === 3) {
          this.truth = '> 65%'
          return "II"
        } else if (lev === 4) {
          this.truth = '> 70%'
          return "III"
        } else if (lev === 5) {
          this.truth = '> 75%'
          return "IV"
        }
      },
      changeHelper() {
        $('#buttEdit').hide()
        let url = this.urlHelpers[Math.floor(Math.random() * this.urlHelpers.length)]
        this.$store.dispatch('loadUrl', url.url)
        this.setUrl = url
        this.nameHelper = url.name
        this.truth = ''
        this.clarity = Math.floor(Math.random() * 51) + 30
        this.numAsk = 0
        localStorage.setItem('numAsk', 0)
        this.cardHelper(url.url, url.name)
      },
      async cardHelper(name, nameHelper) {
        // this.url = `./assets/img/${name}`
        this.url = require('@/assets/img/' + name)
        // this.url = `img/${name}`
        document.getElementById('apDiv149t').style.opacity = '0'
        document.getElementById('stamp').style.opacity = '0'
        document.getElementById('set').style.opacity = '0'
        document.getElementById('wrap').style.background = 'transparent'
        let $div = $("#mag")
        let height = 243 //$div.height();
        let width = 316 //$div.width();
        const k = window.innerWidth > 960 ? 40 : 80
        $div.css({
          'height': 0, 'width': 0, 'margin-top': height / 2, 'margin-bottom': height / 2,
          'margin-left': width / 2 - k, 'margin-right': width / 2, 'padding': 0,
        }).animate({
          'height': height - k, 'width': width - k, 'margin-top': 0,
          'margin-bottom': 0, 'margin-left': "20px", 'margin-right': 0,
        }, 1600)

        $div.animate({
          'width': '120px', 'height': '109px', 'opacity': 1,
          'margin-top': $div.height() / 2, 'margin-left': $div.width() / 2,
        }, 1000)
        $div.css({'left': '2px', 'top': '8px'})
        await this.sleep(2800)
        document.getElementById('wrap').style.background = '#e4c8a2'
        document.getElementById('apDiv149t').style.opacity = '1'
        this.level = this.getRandomNumber(1, 4)
        this.levelAnswer = this.getLevel(this.level)
        $('#buttEdit').hide()
        $('.level').hide()
        $('.pos-vaue').hide()
        document.getElementById('set').style.opacity = '1'
        $('.level').show()
        $('.pos-vaue').show()
        $('#buttEdit').show()
        await this.sleep(1000)
        document.getElementById('stamp').style.opacity = '1'
        await this.sleep(1000)
        if (this.isEsteblish) document.getElementById('dial').style.opacity = '1'
        await this.sleep(200)
        localStorage.setItem('myMag', this.url)
        localStorage.setItem('name', this.nameHelper)
        localStorage.setItem('level', this.level)
        // this.isButtonDisabled1 = false
      },
      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
      },
      async about(num) {
        if (num === 1) {
          this.isWrapper = true
          if (this.isScreen320) {
            document.getElementById('apDiv8').style.display = 'none'
            if (document.getElementById('apDiv23').style.display = 'block') {
              document.getElementById('apDiv23').style.display = 'none'
            }
            document.getElementById('apDiv85').style.display = 'none'
            document.getElementById('apDiv93').style.display = 'none'
            document.getElementById('apDiv3').style.display = 'none'
          } else {
            document.getElementById('wrap85').style.display = 'none'
          }
          $('#apDiv81').animate({height: "show"}, 600)
        } else {
          this.isWrapper = false
          $('#apDiv81').animate({height: "hide"}, 600)
          $('#apDiv88').hide(600)
          $('.menu').show(600)

          function func() {
            document.getElementById("apDiv86").style.opacity = '0'
            $('#apDiv85').show(0)
            $("#apDiv86").animate({opacity: "1"}, 0)
            document.getElementById('apDiv8').style.display = 'block'
          }

          setTimeout(func, 600)
          await this.sleep(600)
          document.getElementById('apDiv3').style.display = 'block'
          document.getElementById('apDiv2').style.display = 'block'
          document.getElementById('wrap85').style.display = 'block'
          this.isPayPal = false
          this.donationAmount = 0
        }
        // document.getElementById('apDiv88').style.display = 'none'
        
      },
      info() {
        this.isWrapper = true
        document.getElementById("apDiv25").style.display = 'block'
        document.getElementById("apDiv2").style.display = 'none'
        document.getElementById("apDiv8").style.display = 'none'
      },
      info1() {
        this.isWrapper = true
        document.getElementById("apDiv26").style.display = 'block'
        document.getElementById("apDiv131").style.display = 'none'
        document.getElementById("apDiv108").style.display = 'none'
        // document.getElementById("apDiv8").style.display = 'none'
      },
      closeInfo() {
        this.isWrapper = false
        document.getElementById("apDiv25").style.display = 'none'
        document.getElementById("apDiv26").style.display = 'none'
        document.getElementById("apDiv23").style.display = 'none'
        document.getElementById("apDiv81").style.display = 'none'
        document.getElementById("apDiv131").style.display = 'block'
        document.getElementById("apDiv108").style.display = 'block'
        document.getElementById("apDiv2").style.display = 'block'
        document.getElementById("apDiv8").style.display = 'block'
        document.getElementById("wrap85").style.display = 'block'
        document.getElementById("apDiv85").style.display = 'block'
        document.getElementById("apDiv3").style.display = 'block'
        // this.isPayPal = false
        // this.donationAmount = 0
      },
      async choose() { //todo
        if (this.isButtonDisabled) {
          const text = document.getElementById('qv1').value
          localStorage.setItem('check', this.isCheckboxChecked1)
          if (this.isCheckboxChecked1) {
            localStorage.setItem('ask', this.intFace[64])
          } else {
            localStorage.setItem('ask', `${this.intFace[41]}:  ${text}?`)
          }
/*
          let questionWords = ["кто", "кого", "кому", "когда", "кем", "что", "чего", "чему", "чем", "отчего",
            "какая", "какие", "какому", "какой", "какое", "какого", "каких", "каким", "каков", "какова",
            "каковы", "о каких", "о каком", "какую", "чей", "чья", "чьей", "чью", "о чьей", "чьи", "чьего",
            "чьему", "о чьем", "чьих", "чьим", "о чьих", "чьими", "который", "которая", "которые", "которого",
            "сколько", "скольких", "скольким", "сколькими", "где", "куда", "зачем", "откуда", "как", "почему",
            "о ком", "о чем", "каково", "каковой", "каковы", "отчего", "или"]
*/
          let lowerCaseText = text.toLowerCase()
          let hasQuestionWord = this.questionWords.some(word => lowerCaseText.includes(word))
          this.$store.dispatch('loadText', text)
          if (hasQuestionWord || this.isCheckboxChecked) {
/*
            for (let i = 1; i < 7; i++) {
              // document.getElementById("ot" + i).disabled = false
            }
*/
            this.checkFields()
            document.getElementById("apDiv23").style.display = 'block'
            // document.getElementById("apDiv25").style.display = 'none'
            document.getElementById("apDiv2").style.display = 'none'
            document.getElementById("apDiv8").style.display = 'none'
            // this.isWrapper = true
            this.$store.dispatch('showMulti', true)
            localStorage.setItem('isShowMulti', true)
          } else {
            this.isMultiAsk = false
            this.$store.dispatch('showMulti', false)
            localStorage.setItem('isShowMulti', false)
            // document.body.style.background = 'url("img/101.png")'
            await this.sleep(100)
            this.$router.push({path: '/choose'})
          }
        }
      },
      multiAsk(n2) {
        if (n2 === 1) {
          this.isWrapper = false
          document.getElementById("apDiv25").style.display = 'none'
          document.getElementById("apDiv23").style.display = 'none'
          document.getElementById("apDiv2").style.display = 'block'
          document.getElementById("apDiv8").style.display = 'block'
        }
        if (n2 === 2) {
          this.isWrapper = false
          document.getElementById("apDiv26").style.display = 'none'
          document.getElementById("apDiv131").style.display = 'block'
          document.getElementById("apDiv108").style.display = 'block'

/*
          document.getElementById("apDiv23").style.display = 'none'
          document.getElementById("apDiv2").style.display = 'block'
          document.getElementById("apDiv8").style.display = 'block'
*/
        }
      },
      multiAskEnter(n2) {
        if (n2 === 5) {
          document.getElementById("ot1").value = ''
          document.getElementById("ot2").value = ''
          document.getElementById("ot3").value = ''
          document.getElementById("ot4").value = ''
          document.getElementById("ot5").value = ''
          document.getElementById("ot6").value = ''
          // document.getElementById("ot7").value = ''
          // document.getElementById("ot8").value = ''
          // document.getElementById("ot9").value = ''
        }
        if (n2 === 6) {
          this.dataMultiAsk = this.dataMultiAsk.filter(item => item !== "")
          this.$store.dispatch('dataMultiAsk', this.dataMultiAsk)
          localStorage.setItem('dataMultiAsk', JSON.stringify(this.dataMultiAsk))
          document.getElementById("apDiv23").style.display = 'none'
          document.getElementById("apDiv8").style.display = 'block'
          this.$store.dispatch('showMulti', true)
          this.isWrapper = false
          this.$router.push({path: '/choose'})
        }
      },
/*
      preloadImage(n, url, ext) {
        const img = new Image()
        img.src = `${url}.${ext}`
        img.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0)
          const imgData = canvas.toDataURL(`image/${ext}`)
          localStorage.setItem(`backgroundImage${n}`, imgData)
        }
      },
*/
/*
      preloadImage(n, imgSrc, ext) {
        const img = new Image();
        img.src = imgSrc;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          const imgData = canvas.toDataURL(`image/${ext}`);
          localStorage.setItem(`backgroundImage${n}`, imgData);
        };
      },
      loadImage(k, n, url, ext) {
        const imgData = localStorage.getItem(`backgroundImage${n}`)
        if (imgData) {
          if (k === 0) {
            document.body.style.backgroundImage = `url(${imgData})`
          } else {
            document.getElementById("apDiv8").style.backgroundImage = '../img/images3.jpg'
          }
        } else {
          if (k === 0) {
            document.body.style.backgroundImage = `${url}.${ext}`
          } else {
            document.getElementById("apDiv8").style.backgroundImage = '../img/images3.jpg'
          }
          this.preloadImage(n, url, ext)
        }
      },
*/
    },
  }
</script>

<style scoped>
  .pos-vr{
    margin:0 7px;
  }
  .scroll{
    scrollbar-width:none;
  }
/*
  .overflow{
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
  }
*/
  .settings{
    position:relative;
    font-size:10px;
    width:94px;
    line-height:1.7;
    text-align:start;
    padding-top:4px;
    padding-left:7px;
  }
  .level{
    font-weight:bold;
    font-size:14px;
    text-align:center;
    text-decoration:underline;
    position:relative;
    top:-4px;
  }
/*
  .grid-container{
    display:flex;
    flex-wrap:wrap;
    width:180px;
  }
*/
/*
  .pos-hr{
    display:flex;
    width:100%;
  }
*/
/*
  .pos-vr-container{
    display:flex;
    flex-basis:33.33%;
    box-sizing:border-box;
    padding:5px;
    width:55%;
  }
  .pos-vr-container {
    display: flex;
    flex-basis: 50%;
    box-sizing: border-box;
    padding: 5px;
  }
*/
  
  .text-de {
    left: -61px;
    top: -50px;
    width: 109px;
  }
  .vrn-de {
    font-size:15px !important;
  }
  #vrn{
    margin-bottom:14px;
  }
  .pos-value{
    font-size:12px;
    position:relative;
    top:45px;
    text-align:center;
    font-weight:bold;
  }
  #lengv {
/*
    position: absolute;
    top: 27px; !*15px;*!
    right: 339px; !*17px;*!
*/
  }
  .wr {
    background: transparent;
    position: absolute;
    height: 50px;
    width: 254px;
    left: 0;
    top: 138px;
    z-index: 100;
  }
/*
  #apDiv108{
    position:absolute;
    !*left: 223px;*!
    !*top: 3px;*!
    width:25px;
    height:25px;
    z-index: 52;
    font-family:"Comic Sans MS", cursive;
    font-style:italic;
    font-weight:bold;
    rotate:10deg;
    font-size:18px;
    padding-right:2px;
    cursor:pointer;
  }
  #aoDiv108:hover {
    border: 1px solid #4c2c0b;
    border-radius: 50%;
    !*cursor: pointer;*!
  }
*/
  #apDiv26 {
    background-color: transparent;
    font-size: 15px;
    font-family: "Comic Sans MS", cursive;
    color: #4C2C0B;
    position: absolute;
    width: 336px;
    height: 241px;
    /*left: 27px;*/
    /*top: 35px;*/
    display:none;
    z-index: 171;
    bottom: 0;
    right: 68px;
  }
#apDiv85 {
  display:block;
}
  /*
    [v-cloak] {
      !*display: none !important;*!
      background:#000 !important;
    }
  */
  .menu {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    left: -20px;
    top: 120px;
    width:46%;
    line-height:2.5;
    color: #4C2C0B;
  }

  .menu-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor:pointer;
  }
  .menu-item:hover {
    /*text-decoration:underline;*/
  }
/*
  .menu-item i {
    margin-right: 10px;
  }
*/
  .icon {
    margin-right:10px;
  }

  .menu-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    transition: background-color 0.3s, transform 0.3s;
  }
  .menu-item:hover {
    background-color: #f3e9cd;
    transform: scale(1.05);
  }
  .menu-item .icon {
    margin-right: 10px;
    width: 24px;
    height: 24px;
    transition: filter 0.3s;
  }
  .menu-item:hover .icon {
    filter:brightness(0.8);
  }
.row {
  display:flex;
  justify-content:flex-start;
  top: 15px;
  position: relative;
}
  .info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top:15px;
  }
  .contact, .youtube, .video {
    margin-bottom: 10px;
  }
  .design {
    margin-top: 10px;
  }
  a:link, a:visited {
    color: #1a73e8;
    text-decoration: none;
  }
  a:hover{
    text-decoration:underline;
  }
.txt-inf {
  overflow: scroll;
  scrollbar-width: none;
  height: 174px;
}
  .about {
    font-size: 16px;
    color: #2c3e50;
    margin-top: 15px;
    text-align: left;
  }
  .row input {
    width: 50px;
    height:20px;
    margin-right:15px;
  }
/*
#paypal-button-container{
  border: 1px solid;
  border-radius: 5px;
  margin-top: 10px;
}
*/
/*
  .paypal-button-container {
    width: 81%;
    position: relative;
    top: -5px;
    padding: 15px 15px 0;
    margin: 56px -9px;
    background-color: #f0e1cd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
*/
/*
  .paypal-button-container:hover {
    transform: translateY(-2px); !* Подъем при наведении *!
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); !* Увеличение тени при наведении *!
  }
*/

  /* Стили для модального окна */
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
  }
</style>

