<template>
  <div :class="backgroundClass">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "AppLayout.vue",
    computed: {
      backgroundClass() {
        const routeName = this.$route.name;
        return `${routeName}-background`;
      }
    }
  }
</script>