import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import AppLayout from './layout/AppLayout.vue';
import "./assets/css/all.css";

const app = createApp(App);

app.component('AppLayout', AppLayout);
app.use(store).use(router).mount('#app');

// Условное подключение стилей
if (window.matchMedia('(min-width: 525px) and (max-width: 960px)').matches) {
  import('./assets/css/bet524-960.css');
}
if (window.matchMedia('(min-width: 700px) and (max-width: 960px)').matches) {
  import('./assets/css/bef700-960.css');
}
if (window.matchMedia('only screen and (max-width: 526px)').matches) {
  import('./assets/css/befor525.css');
}